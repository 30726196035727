



















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { VeeValidateObserverRef } from '@/models/Common/Validation'
import UserService from '@/services/UserService'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'
import { getModule } from 'vuex-module-decorators'
import moment from 'moment'
//@ts-ignore
import _ from 'lodash'
const UserModule = getModule(UserInfo, store)

@Component
export default class ShowPersonal extends Vue {
  $refs!: {
    observer: VeeValidateObserverRef
  }
  private showPersonal: boolean = true
  public personal: any = {
    user_info_display_option: true,
    completed_thing: '',
    desired_skill: '',
    certificates_and_achievements: '',
    want_to_do_in_the_future: '',
    want_to_work: '',
  }

  created() {
    this.getUserPersonal()
  }

  getUserPersonal() {
    UserService.getUserPersonal()
      .then(res => {
        if (res.status === 200) {
          if (!_.isEmpty(res.data)) {
            // UserModule.HANDLE_USER_PERSONAL_INFO(res.data)
          }

          this.personal = {
            ...store.state.userInfo.user.personal_info
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.$emit('handleUserPersonal', this.personal))
  }

  updatePersonal() {
    this.$refs.observer.validate().then(isValidate => {
      if (!isValidate) {
        return
      } else {
        const new_personal = {
          user_info_display_option: this.personal.user_info_display_option,
          completed_thing: this.personal.completed_thing,
          desired_skill: this.personal.desired_skill,
          certificates_and_achievements: this.personal.certificates_and_achievements,
          want_to_do_in_the_future: this.personal.want_to_do_in_the_future,
          want_to_work: this.personal.want_to_work,
        }
        UserService.updateUserPersonal(new_personal)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              res.data.url = JSON.parse(res.data.url)
              UserModule.HANDLE_USER_PERSONAL_INFO(res.data)
              this.$emit('isPersonalUpdated', true)
              this.$emit('handleUserPersonal', this.personal)
            }
          })
          .catch(error => {
            this.getUserPersonal()
            this.$emit('isPersonalUpdated', false)
          })
      }
    })
  }
}
